<template>
    <b-container fluid>
        <iq-card>
          <template v-slot:headerTitle>
              <h4 class="card-title">{{$t('dealer_management.dealer_report')}}</h4>
          </template>
            <template v-slot:body>
                <b-row>
                  <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="registration_no"
                      >
                        <template v-slot:label>
                          {{$t('dealer_management.dealer_registration_no')}}<span class="text-danger"></span>
                        </template>
                        <b-form-input
                          plain
                          v-model="search.registration_no"
                          id="registration_no"
                          >
                          </b-form-input>
                      </b-form-group>
                  </b-col>
                  <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="name"
                      >
                        <template v-slot:label>
                          {{$t('dealer_management.dealer_name')}}<span class="text-danger"></span>
                        </template>
                        <b-form-input
                          plain
                          v-model="search.name"
                          id="name"
                          >
                          </b-form-input>
                      </b-form-group>
                  </b-col>
                  <!-- <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="fiscal_year_id"
                      >
                        <template v-slot:label>
                          {{$t('globalTrans.fiscalYear')}}<span class="text-danger"></span>
                        </template>
                        <b-form-select
                          plain
                          v-model="search.fiscal_year_id"
                          :options="fiscalYearList"
                          id="fiscal_year_id"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                      </b-form-group>
                  </b-col> -->
                  <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="date_from"
                      >
                        <template v-slot:label>
                          {{$t('globalTrans.from_date')}}  <span class="text-danger"></span>
                        </template>
                        <b-form-input
                          class="date-picker"
                          v-model="search.date_from"
                          id="date_from"
                          >
                          </b-form-input>
                      </b-form-group>
                  </b-col>
                  <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="date_to"
                      >
                        <template v-slot:label>
                          {{$t('globalTrans.to_date')}}  <span class="text-danger"></span>
                        </template>
                        <b-form-input
                          class="date-picker"
                          v-model="search.date_to"
                          id="date_to"
                          >
                          </b-form-input>
                      </b-form-group>
                  </b-col>
                  <b-col class="col-sm-2 col-lg-12">
                      <b-button type="button" variant="primary float-right" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                  </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{$t('dealer_management.dealer_report')}}</h4>
                    </template>
                    <template v-slot:headerAction1>

                    </template>
                    <template v-slot:headerAction>
                        <!-- <b-button variant="primary" v-b-modal.modal-4 @click="resetId">
                             {{  $t('globalTrans.add_new') }}
                        </b-button> -->
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" striped bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                        <template v-slot:cell(index)="data">
                                        {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(sell_date)="data">
                                          {{ data.item.sell_date | dateFormat }}
                                        </template>
                                        <template v-slot:cell(fertilizer_qty)="data">
                                          {{ $n(data.item.fertilizer_qty, { useGrouping: false }) }}
                                        </template>
                                        <template v-slot:cell(fertilizer_amount)="data">
                                          {{ $n(data.item.fertilizer_amount, { useGrouping: false }) }}
                                        </template>
                                        <!-- <template v-slot:cell(fiscal_year_bn)="data">
                                           {{ data.item.fiscal_year_bn }}
                                        </template> -->
                                        <!-- <template v-slot:cell(fiscal_year_bn)="data">
                                           {{ $n(data.item.fiscal_year_bn.split('-')[0], { useGrouping: false }) + '-' +  $n(data.item.fiscal_year_bn.split('-')[1], { useGrouping: false }) }}
                                        </template> -->
                                    </b-table>
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-5" size="lg" :title="$t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <Details :id="editItemId" :items="items"/>
        </b-modal>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import Details from './Details'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { dealerReportList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import Store from '@/store'

export default {
    mixins: [ModalBaseMasterList],
    components: {
        Details
    },
    data () {
        return {
            search: {
                name: '',
                registration_no: '',
                date_from: '',
                date_to: ''
            },
            items: ''
        }
    },
    computed: {
        fiscalYearList: function () {
          const fiscalYears = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
          const tempData = fiscalYears.map(item => {
            if (this.$i18n.locale === 'bn') {
              return { text: item.text_bn, value: item.value }
            } else {
              return { text: item.text_en, value: item.value }
            }
          })
          return tempData
        },
        fertilizerNameList: function () {
            return this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList
        },
        formTitle () {
            return this.editItemId === 0 ? this.$t('externalUserIrrigation.sales_entry') + ' ' + this.$t('globalTrans.entry') : this.$t('externalUserIrrigation.sales_entry') + ' ' + this.$t('globalTrans.update')
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
                { label: this.$t('movement.fertilizerName'), class: 'text-center' },
                { label: this.$t('dealer_management.allocation_amount'), class: 'text-center' },
                { label: this.$t('dealer_management.stock_qty'), class: 'text-center' },
                { label: this.$t('dealer_management.sell_amount'), class: 'text-center' }
                // { label: this.$t('dealer_management.stock_balance'), class: 'text-left' }
            ]

            /*eslint-disable */
            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                  { key: 'index' },
                  { key: 'fertilizer_name_bn' },
                  { key: 'allocation_qty' },
                  { key: 'stock_qty' },
                  { key: 'sell_amount' },
                  // { key: 'stock_balance' }
                ]
            } else {
                keys = [
                  { key: 'index' },
                  { key: 'fertilizer_name' },
                  { key: 'allocation_qty' },
                  { key: 'stock_qty' },
                  { key: 'sell_amount' },
                  // { key: 'stock_balance' }
                ]
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        }
    },
    async created () {
        this.loadData()
    },
    mounted () {
      flatpickr('.date-picker', {})
        core.index()
    },
    methods: {
      changeStatusCustom (baseUrl, uri, item) {
          Store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
          RestApi.deleteData(baseUrl, `${uri}/${item.id}`).then(response => {
            if (response.success) {
              window.vm.$toast.success({
                title: this.$t('globalTrans.success'),
                message: this.$t('globalTrans.update'),
                color: '#D6E09B'
              })
            } else {
              window.vm.$toast.error({
                title: 'Error',
                message: 'Operation failed! Please, try again.'
              })
            }
            Store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
          })
        },
        toggleStatusCustom (baseUrl, uri, item) {
          window.vm.$swal({
            title: window.vm.$t('globalTrans.deleteConfirmMsg'),
            showCancelButton: true,
            confirmButtonText: window.vm.$t('globalTrans.yes'),
            cancelButtonText: window.vm.$t('globalTrans.no'),
            focusConfirm: false
          }).then((result) => {
            if (result.isConfirmed) {
              this.changeStatusCustom(baseUrl, uri, item)
            }
          })
        },
        toggleStatusCustom2 (baseUrl, uri, item) {
          window.vm.$swal({
            title: window.vm.$t('globalTrans.finalSaveConfirmMsg'),
            showCancelButton: true,
            confirmButtonText: window.vm.$t('globalTrans.yes'),
            cancelButtonText: window.vm.$t('globalTrans.no'),
            focusConfirm: false
          }).then((result) => {
            if (result.isConfirmed) {
              this.changeStatusCustom(baseUrl, uri, item)
            }
          })
        },
        details (item) {
          this.items = item
        },
        // remove (item) {
        //     this.toggleStatusCustom(seedFertilizerServiceBaseUrl, saleEntryStatus, item)
        // },
        dataChange () {
            this.loadData()
        },
        async searchData () {
            this.loadData()
        },
        loadData () {
            const params = Object.assign({}, this.search, {page: this.pagination.currentPage, per_page: this.pagination.perPage })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.getData(seedFertilizerServiceBaseUrl, dealerReportList, params).then(response => {
                if (response.success) {
                    this.$store.dispatch('setList', response.data)
                    // this.$store.dispatch('setList', this.getRelationalData(response.data.data))
                    // this.paginationData(response.data)
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        },
        dataFilter (data) {
          return data.filter(item => item.status === 2)
        },
        getRelationalData(data) {
          const fiscalYears = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
          const listData = data.map(item => {
            const fiscalYearObj = fiscalYears.find(doc => doc.value === item.fiscal_year_id)
            const fiscalYearData = {}
            if(typeof fiscalYearObj !== 'undefined') {
              fiscalYearData.fiscal_year = fiscalYearObj.text_en
              fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
            } else {
              fiscalYearData.fiscal_year = ''
              fiscalYearData.fiscal_year_bn = ''
            }

            return Object.assign({}, item, fiscalYearData)
          })
          return listData
        }
    }
}
</script>

<style>
  .modal-title {
    flex-grow: 1 !important;
  }
</style>
