<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col>
                <b-table bordered hover :items="detailsInfo" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                  <template v-slot:cell(index)="data">
                  {{ $n(data.index + 1) }}
                  </template>
                  <template v-slot:cell(quantity)="data">
                    {{ $n(data.item.quantity, { useGrouping: false }) }}
                  </template>
                  <template v-slot:cell(total_price)="data">
                    {{ $n(data.item.total_price, { useGrouping: false }) }}
                  </template>
              </b-table>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
export default {
  props: ['id', 'items'],
  async created () {
    this.detailsInfo = this.items.dealer_sell_details.map(item => {
      const fertilizerObject = this.fertilizerNameList.find(data => data.value === item.fertilizer_id)
      const fertilizerData = { fertilizer_name: fertilizerObject.text, fertilizer_name_bn: fertilizerObject.text_bn }
      return Object.assign({}, item, fertilizerData)
    })
  },
  data () {
    return {
      detailsInfo: []
    }
  },
  computed: {
    fertilizerNameList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList
    },
    columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
                { label: this.$t('movement.fertilizerName'), class: 'text-left' },
                { label: this.$t('externalUserIrrigation.fertilizer_qty'), class: 'text-left' },
                { label: this.$t('externalUserIrrigation.fertilizer_amount'), class: 'text-left' }
            ]

            /*eslint-disable */
            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                  { key: 'index' },
                  { key: 'fertilizer_name_bn' },
                  { key: 'quantity' },
                  { key: 'total_price' }
                ]
            } else {
                keys = [
                  { key: 'index' },
                  { key: 'fertilizer_name' },
                  { key: 'quantity' },
                  { key: 'total_price' }
                ]
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        }
  },
  methods: {
    getFormattedDate (date) {
      const dateArr = date.split('-')
      return this.$n(parseInt(dateArr[0]), { useGrouping: false }) + '-' + this.$n(parseInt(dateArr[1]), { useGrouping: false }) + '-' + this.$n(parseInt(dateArr[2]), { useGrouping: false })
    },
    getPortAllocationData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    }
  }
}
</script>
